<template lang="html">
  <div class="row">
    <div id="content-wrap" class="content-wrapper pt-1">
      <h4 class="text-center">请选择要进入的代理</h4>
      <div class="content" style="max-width:600px;margin:auto">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-nowrap" scope="col">#</th>
                <th class="text-nowrap" scope="col">名称</th>
                <th class="text-nowrap" scope="col">负责人</th>
              </tr>
            </thead>
            <tbody v-if="loading">
              <tr>
                <th colspan="4">
                  <i class="fas fa-spinner fa-pulse"></i> 请稍候……
                </th>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="i in groups">
                <th class="text-nowrap" scope="row">{{i.agentNo}}</th>
                <td class="text-nowrap text-bold"><router-link :to="{ name: 'GroupHome',params: { groupId: i.id }}">{{i.title}}</router-link></td>
                <td class="text-nowrap"><span v-if="i.user">{{i.user.nickName}}</span></td>
              </tr>
            </tbody>
            <!--<tfoot>
                <tr>
                    <td colspan="4">
                    </td>
                </tr>
            </tfoot>-->
          </table>
        </div>
        <paging :page-index="pager.pageIndex"
                :total="pager.totalRecords"
                :page-size="pager.pageSize"
                :per-pages="3"
                :show-go="true"
                :prePageSize="pager.prePageSize"
                :onPagerChange="pageChange">
        </paging>
      </div>
    </div>

    <footer class="main-footer text-sm">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> 6.0
      </div>
      <strong>Copyright &copy; 2015-2020 <a href="https://panorover.com">PanoRover.com</a>.</strong> All rights reserved.
    </footer>
  </div>
  <!-- /.row -->
</template>

<script>
  export default {
    data() {
      return {
        groups: null,
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 10,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 10,
          sort: 'AddTime',
          order: 'desc',
          fields: 'Title',
          key: null,
        },
        loading: false,
      }
    },
    created() {
    },
    mounted() {
      document.getElementsByTagName("body")[0].classList.add("layout-footer-fixed")
      this.loadData()
    },
    destroyed() {
      document.getElementsByTagName("body")[0].classList.remove("layout-footer-fixed")
    },
    computed: {
    },
    methods: {
      loadData: function () {
        this.loading = true;
        var config = {
          params: {
            page: this.query.pageIndex,
            pageSize: this.query.pageSize,
            sort: this.query.sort,
            order: this.query.order,
            fields: this.query.fields,
            key: this.query.key,
          }
        };
        this.$axios.get("/Api/GroupAdmin/Groups", config).then((res) => {
          if (res.data.data.length == 0) {
            this.$router.push({ name: 'NoAccess' })
            return
          } else if (res.data.data.length == 1) {
          //  this.rotuerTo(res.data.data[0])
          //  return
          }
          this.groups = res.data.data
          this.pager.pageIndex = res.data.pageIndex
          this.pager.pageSize = res.data.pageSize
          this.pager.totalPages = res.data.totalPages
          this.pager.totalRecords = res.data.totalRecords
          this.loading = false;
        }).catch((error) => {
          console.log(error)
          switch (error.status) {
            case 401:
              this.$router.push({ name: 'NoAccess' })
              break;
            case 403:
              this.$router.push({ name: 'NoAccess' })
              break;
          }
          //this.$router.push({ name: 'NoAccess' })
        })
        this.loading = false;
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      rotuerTo(data) {
        this.$router.push({ name: 'GroupHome', params: { groupId: data.id } })
      },
    },
    components: {
    }
  }
</script>

<style lang="css">
</style>
